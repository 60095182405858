
import './App.css';
import Bar from './components/Bar.jsx';
import Profile from './Page/Profile.jsx';


function App() {
  return (
    <div className="App">



      <Bar/>
      <Profile/>



    </div>
  );
}

export default App;
