import React from 'react'
import './Profile.css';

const profile = () => {
  return (

    <div className=' Container'>
      <div className='Box'>
        <img src="logo.png" alt="" />
        <h4>First name</h4>
        <h4>Last name</h4>
        <p>Email</p>
        <p>Hobbies</p>
      <div className="Box blue"> <h4>ny cribe</h4></div>
      <div className="Box"><h4>all on point</h4></div>
      <div className="Box"><h4> we are the best</h4></div>
    </div>
   </div>
  

  )
}

export default profile
