import React from 'react'
import './Bar.css'
const Bar = () => {
  return (
    <div>
      <nav>
       <a href="" className="logo"><h3>LOGO</h3></a>
       <ul className="head">
         <a href=""> Home</a>
         <a href="">Services</a>
         <a href="">About us</a>
         <a href=""> Contact</a>
       </ul>
      </nav>
    </div>
  )
}

export default Bar
